export const USER_NAME = "25";
export const MIDDLE_NAME_MAXLENGTH = "25";
export const DEFAULT_PLANS_PER_PAGE_SIZE = 5;

export const STATE_CODE = 12;

// Timezone offset of EST, which is used for some server side configurations
export const TIMEZONE_OFFSET = 300;

/** API REQUESTS --- START ---  */
export const INITIALIZED = 'initialized';
export const SUCCESS = 'success';
export const FETCHING = 'fetching';
export const ERROR = 'error';
export const STATUS = 'status';
export const DATA = 'data';
/** API REQUESTS --- END ---  */

/** LOCALE CONSTANTS */
export const ENG = "ENG";
export const ESP = "ESP";

/** SUBSIDY ESTIMATE --- START ---  */
export const APTC = "aptc";
export const MEMBERS = "members";
/** SUBSIDY ESTIMATE --- END ---  */

/** VIEW ID --- START ---  */
export const CWS_PLANS_MAIN = "CWS_PLANS_MAIN";
export const CWS_PLANS_COMPARE = "CWS_PLANS_COMPARE";
export const CWS_PLANS_DETAILS = "CWS_PLANS_DETAILS";
export const CWS_PLANS_DETAILS_TAB1 = "CWS_PLANS_DETAILS_TAB1";
export const CWS_PLANS_DETAILS_TAB2 = "CWS_PLANS_DETAILS_TAB2";
export const CWS_PLANS_DETAILS_TAB3 = "CWS_PLANS_DETAILS_TAB3";
export const CWS_NQDENTAL_PLANS_DETAIL = "CWS_NQDENTAL_PLANS_DETAIL";
export const CWS_NQDENTAL_PLANS_OVERVIEW = "CWS_NQDENTAL_PLANS_OVERVIEW";
export const CWS_NQDENTAL_PLANS_MAIN = "CWS_NQDENTAL_PLANS_MAIN";
export const MWS_PLANS_MAIN = "MWS_PLANS_MAIN";
/** VIEW ID --- END ---  */

/** PRODUCT SEGMENT :-- Note : Must match the product name from salesconnect db  */
export const PRODUCT_SEGMENT_HEALTH = "Health";
export const PRODUCT_SEGMENT_MARKETPLACE = "Marketplace";
export const PRODUCT_SEGMENT_DENTAL = "Dental";
export const PRODUCT_SEGMENT_USABLE = "USAble";

/** PRODUCT CONSTANTS :-- Note : Must match the product name from salesconnect db  */
export const PRODUCT_QHP = "QHP";
export const PRODUCT_USABLE_LIFE_ACCIDENT = "Accident Policy";
export const PRODUCT_USABLE_LIFE_CRITICAL_ILLNESS = "Critical Illness";
export const PRODUCT_USABLE_LIFE_HOSPITAL = "Hospital Indemnity";
export const PRODUCT_QUALIFIED_DENTAL = "QDental";
export const PRODUCT_NON_QUALIFIED_DENTAL = "NQDental";
export const PRODUCT_DENTAL = "Dental";

/** PRODUCT CLASS CONSTANTS :-- Note : Must match the product class name from salesconnect db  */
export const PRODUCT_CLASS_HEALTH = "Health";
export const PRODUCT_CLASS_MARKETPLACE = "Marketplace";
export const PRODUCT_CLASS_QUALIFIED_DENTAL = "Qualified Dental";
export const PRODUCT_CLASS_NON_QUALIFIED_DENTAL = "Non-Qualified Dental";
export const PRODUCT_CLASS_NON_QUALIFIED_DENTAL_ES = "Planes Dentales No Calificados";

/** RATING KEY CONSTANTS :-- Note : Must match the product class name from salesconnect db */
export const RATING_INDIVIDUAL_QUALIFIED_DENTAL = "IndividualQDental";
export const RATING_INDIVIDUAL_QUALIFIED_HEALTH = "IndividualQHP";
export const RATING_INDIVIDUAL_NONQUALIFIED_DENTAL = "IndividualNQDental";
export const RATING_ANCILLARY_ACCIDENT = "Accident";
export const RATING_ANCILLARY_CRITICAL = "Critical";
export const RATING_ANCILLARY_HOSPITAL = "HospitalIndemnity";

/** Homepage Help Section  */
export const CWS_ACCOUNT_OVERVIEW_EN = "account/overview";
export const CWS_ACCOUNT_OVERVIEW_ES = "account/overview"
export const CWS_MEDICARE_FND_PLAN_EN = "https://medicare.websales.floridablue.com/sbu/age-in/rightsizer";
export const CWS_MEDICARE_FND_PLAN_ES = "https://medicare.websales.floridablue.com/sbu/age-in/rightsizer";
export const CWS_OTHER_PRODUCTS_EN = "other-plans";
export const CWS_OTHER_PRODUCTS_ES = "other-plans";
export const CWS_MEMBER_LOGIN_EN = "https://www.floridablue.com/members/individual/home";
export const CWS_MEMBER_LOGIN_ES = "https://www.floridablue.com/members/individual/home";
export const CWS_MEMBER_PROFILE = "get-quote";

/** ON EXCHANGE KEY CONSTANTS **/
export const APPLICANT_GRP_NUMS = "applicantGroupNumbers";
export const PRIMARY_APPLICANT_SELECTION_DATA = "primaryApplicantSelectionData";
export const TOBACCO_SELECTION_DATA = "tobaccoSelectionData";
export const ENR_GRPS_DATA = "enrGrpsData";
export const TOTAL_SUBSIDY_SELECTED = "totalSubsidySelected";
export const TOTAL_SUBSIDY_AVAILABLE = "totalSubsidyAvailable";
export const ENR_GRPS_SELECTED_PLAN_DATA = "enrGrpsSelectedPlanData";
export const EXCHANGE_APPLICATION = "exchangeApplication";
export const VALIDATE_GRP_SELECTION_FETCHING = "validateGrpSelectionFetching";
export const VALIDATE_GRP_SELECTION_DATA = "validateGrpSelectionData";
export const VALIDATE_GRP_SELECTION_ERROR = "validateGrpSelectionError";
export const VALIDATE_PRIMARY_APPLICANT_SELECTION_FETCHING = "validatePrimaryApplicantSelectionFetching";
export const VALIDATE_PRIMARY_APPLICANT_SELECTION_DATA = "validatePrimaryApplicantSelectionData";
export const VALIDATE_PRIMARY_APPLICANT_SELECTION_ERROR = "validatePrimaryApplicantSelectionError";
export const KEEP_SHOPPING_OTHER_PRODUCT = "keepShoppingOtherProduct";
export const EST_SUBSIDY_CSR_LEVEL = "estimateSubsidyCSRLevel";
export const SHOULD_DISPLAY_STAR_ICON = "shouldDisplayStarIcon";

/** My Account Section */
export const CWS_ACCOUNT_APPLICATION_EN = "/account/applications";
export const CWS_ACCOUNT_APPLICATION_ES = "/account/applications";
/** MWS QHP Receive member  */
export const PLAN_BENEFIT_ANNUAL_DEDUCTIBLE = "Annual Deductible";
export const PLAN_BENEFIT_ANNUAL_MAX_OUT_OF_POCKET =
  "Annual Maximum Out of Pocket";
export const PLAN_BENEFIT_PRIMARY_CARE_PHYSICIAN =
  "Primary Care Visit to Treat an Injury or Illness";
export const PLAN_BENEFIT_GENERIC_DRUGS = "Generic Drugs";
export const AGENCY_TYPE_EXT = "EXT";
export const AGENCY_TYPE_INT = "INT";
export const RISK_FACTOR_CODE_LOW = "LOW";
export const RISK_FACTOR_CODE_MEDIUM = ["MEDIUM", "MED"];
export const RISK_FACTOR_CODE_HIGH = "HIGH";
export const RISK_FACTOR_CODE_DEFAULT = "LOW";
export const MIGRATED_TO_SILVER_PLAN = "Passive - B2S";

/**
 * plans component step constants 
 */
export const PLAN_TABLE = 'plan-table';
export const PLAN_COMPARE = 'plan-compare';
export const PLAN_DETAILS = 'plan-details';

/**
 * rightsizer recommanded plans constants 
 */
export const RS_PLAN_BEST_FIT = 'BEST-FIT';
export const RS_PLAN_BUY_DOWN = 'BUY-DOWN';
export const RS_PLAN_BUY_UP = 'BUY-UP';

/**
 * cart component constants
 */
export const ONEX_CART_ITEMS = 'onExCartItems';
export const OFFEX_CART_ITEMS = 'offExCartItems';

/**
 * Plan Benefits - In and Out network ids
 */
export const IN_NETWORK_ID = '1';
export const OUT_NETWORK_ID = '2';

export const GET_GROUPS_AND_BENEFITS_FETCHING = "groupsAndBenefitsFetching";
export const GET_GROUPS_AND_BENEFITS_DATA = "groupsAndBenefitsData";
export const GET_GROUPS_AND_BENEFITS_ERROR = "groupsAndBenefitsError";
export const GET_BENEFIT_VALUES_FETCHING = "benefitValuesFetching";
export const GET_BENEFIT_VALUES_DATA = "benefitValuesData";
export const GET_BENEFIT_VALUES_ERROR = "benefitValuesError";

/**
 * Member Flow Constants
 */
export const MEMBER_DATA_FETCHING = "memberDataFetching";
export const MEMBER_DATA = "memberData";
export const MEMBER_SET = "memberSet";
export const MEMBER_DATA_ERROR = "memberDataError";
export const RECOMMENDED_PLAN_FETCHING ="recommendedPlanFetching";
export const RECOMMENDED_PLAN_ERROR = "recommendedPlanError";
export const RECOMMENDED_PLAN_DATA = "recommendedPlanData";
export const CURRENT_PLAN_NAME = "currentPlanName";

/**
* CWS-UI Init Data Constants
*/
export const CWS_UI_INIT_DATA_FETCHING = "initDataFetching";
export const CWS_UI_INIT_DATA = "initData";
export const CWS_UI_INIT_DATA_ERROR = "initDataError";

/**
* CWS-UI Authentication Data Constants
*/
export const CWS_UI_AUTH_DATA_FETCHING = "authDataFetching";
export const CWS_UI_AUTH_DATA = "authData";
export const CWS_UI_AUTH_DATA_ERROR = "authDataError";

/**
* CWS-UI USER Data Constants
*/
export const USER_DATA_FETCHING = "userDataFetching";
export const USER_DATA = "userData";
export const USER_DATA_FETCH_ERROR = "userDataFetchError";

/**
* PLAN DETAILS Constants
*/
export const PRODUCT_SEGMENT = "productSegment";
export const IS_ON_EXCHANGE = "isOnExchange";
export const IS_MEG_APP = "isMultiEnrollmentGroupApplication";
export const IS_NEXT_GRP_PRESENT = "isNextGroupPresent";
export const SELECTED_ENR_GRP_NUM = "selectedEnrollmentGroupNum";
export const SELECTED_PLAN_DATA = "selectedPlanData";
export const IS_SUBSIDY_FLOW = 'isSubsidyFlow';


/**
* PLAN COMPARE Constants
*/
export const SELECTED_PLANS_FOR_COMPARE = "selectedPlansForCompare";
export const PRODUCT_CODE = "productCode";

/*
 * ACC Flow Constants
 */
export const PRCT_CLASS_ID_SAQD = 10;
export const PRCT_CLASS_ID_NONSAQD = 7;
export const PRCT_CLASS_ID_QHP_ONEX = 9;
export const PRCT_CLASS_ID_QHP_OFFEX = 8;
export const PRCT_CLASS_ID_ACCIDENT = 1;
export const PRCT_CLASS_ID_CRITICAL = 2;
export const PRCT_CLASS_ID_HOSPITAL = 3;

/**
 * PRE-BILL Constants
 */
export const PREBILL_DATA = "prebillData";

export const VALID_ON_EX_CSR_LEVELS = ['1', '2', '3', '4', '5', '6', '01', '02', '03', '04', '05', '06'];

