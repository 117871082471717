import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { UIAlert } from "digital-shared-ui";
import { Markup } from "interweave";
import { useDispatch } from 'react-redux';
import { acceptCookieDisclaimer } from 'actions/PeopleAction';

function CookieDisclaimer() {
    const dispatch = useDispatch();
    const { t } = useTranslation('Layout');

    const [showCookieDisclaimer, setShowCookieDisclaimer] = useState(true);

    const cookieDisclaimerAccept = () => {
        dispatch(acceptCookieDisclaimer(true));
        setShowCookieDisclaimer(false);
    };

    const cookieDisclaimerDeny = () => {
        window.location = t('internetPrivacyStatementUrl');
    };

    return (
        <>
        {showCookieDisclaimer &&
            <UIAlert id='cookieDisclaimer' ariaLabel='service error alert'
                className='alert fixed-bottom' >
                <div className='large-flex-center row'>
                    <div className='columns large-9 medium-12 small-12'>
                        <h2 className='hl-small top-0x'>{t('cookieDisclaimer.cookieDisclaimerTitle')}</h2>
                        <p className='large-bottom-0x'><Markup content={t('cookieDisclaimer.cookieDisclaimerBody')} /></p>
                    </div>
                    <div className='columns large-3 medium-12 small-12 text-center'>
                        <button className='primary small expand' onClick={cookieDisclaimerAccept}>{t('cookieDisclaimer.cookieDisclaimerAccept')}</button>
                        <button className='secondary small expand collapse' onClick={cookieDisclaimerDeny}>{t('cookieDisclaimer.cookieDisclaimerDecline')}</button>
                    </div>
                </div>
            </UIAlert>
        }
        </>
    );
};

export default CookieDisclaimer;