import { useAxiosHandler } from 'hooks/useAxiosHandler';
import * as Env from '../constants/Environment';
import mockViewData from '../../data/views.json';
import mockBenefitViewData from '../../data/benefitviews.json';
import mockBenefitData from '../../data/benefits.json';
import { getDateStr } from 'utils/commonUtils';
import * as Constants from "constants/Constants";

const axios = useAxiosHandler()

export const SAVE_VIEW_DATA = "SAVE_VIEW_DATA";
export const RESET_VIEWS_BENEFITS_REDUCER = 'RESET_VIEWS_BENEFITS_REDUCER';

export const GET_GROUPS_AND_BENEFITS_INIT = 'GET_GROUPS_AND_BENEFITS_INIT';
export const GET_GROUPS_AND_BENEFITS_SUCCESS = 'GET_GROUPS_AND_BENEFITS_SUCCESS';
export const GET_GROUPS_AND_BENEFITS_FAILURE = 'GET_GROUPS_AND_BENEFITS_FAILURE';

export const GET_BENEFIT_VALUES_INIT = 'GET_BENEFIT_VALUES_INIT';
export const GET_BENEFIT_VALUES_SUCCESS = 'GET_BENEFIT_VALUES_SUCCESS';
export const GET_BENEFIT_VALUES_FAILURE = 'GET_BENEFIT_VALUES_FAILURE';

export const RESET_BENEFITS_AND_BENEFITVALUES_DATA = "RESET_BENEFITS_AND_BENEFITVALUES_DATA";

const saveViewData = (data) => ({
    type: SAVE_VIEW_DATA,
    payload: data
})

export const resetViewBenefitsReducer = () => ({
    type: RESET_VIEWS_BENEFITS_REDUCER
})

export const resetBenefitsAndBenefitValues = () => ({
    type: RESET_BENEFITS_AND_BENEFITVALUES_DATA
})

const buildBenefitsRequest = (benefitIds, plans, productCode, effDate) => {
    let request = {
        benefitIds: benefitIds,
        effectiveDate: effDate?.toISOString(),
        locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
        planIds: plans
    };

    if (productCode !== "2000" && productCode !== "5000")
        request.networkId = "1"

    return request;
}

export const getBenefitViews = async (views, effDate) => {
    let benefitIds = [];
    let benefitGroups = [];
    let benefitTabViews = [];
    let response = {};
    let viewIds = [];
    
    views.forEach(viewObj => { viewIds.push(viewObj.viewId) });

    const url = `/products/views/benefitviews`;

    let params = {
        applicationName: 'CWS',
        viewIds: `${viewIds}`,
        locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
        effectiveDate: getDateStr(effDate, "mm/dd/yyyy")
    }

    if (Env.ISPRODUCTION) {
        let apiResp = await axios.get(url, { params, timeout: 2000 });
        response = apiResp.data;
    } else {
        let sortedMock = [];
        viewIds.forEach(viewId => {
            let test = mockBenefitViewData.planBenefitViews;
            sortedMock.push(test.find((planBenefitView) => planBenefitView.viewId === viewId));
        })
        response["planBenefitViews"] = sortedMock;
        console.log("test :", response);
    }

    response.planBenefitViews.forEach(view => {
        if (view)
            benefitTabViews.push(view);
        view.benefitGroupViews.sort((benefitGroupView1, benefitGroupView2) => (benefitGroupView1.ordinal >= benefitGroupView2.ordinal) ? 1 : -1);
        view.benefitGroupViews.forEach(benefitGroupView => {
            benefitGroups.push(benefitGroupView);
            benefitGroupView.benefitViews.sort((benefitView1, benefitView2) => (benefitView1.ordinal >= benefitView2.ordinal) ? 1 : -1);
            benefitGroupView.benefitViews.forEach((benefitView) => {
                benefitIds.push(benefitView.benefitId);
            })
        })
    })
    return { benefitIds, benefitGroups, benefitTabViews };
}

const getGroupsAndBenefitsForAllViewIdsFailure = (error) => {
    return {
        type: GET_GROUPS_AND_BENEFITS_FAILURE,
        error
    }
}

const getGroupsAndBenefitsForAllViewIdsSuccess = (data) => {
    return {
        type: GET_GROUPS_AND_BENEFITS_SUCCESS,
        data
    }
}

export const getGroupsAndBenefitsForAllViewsIds = (viewsIdsArray, effDate) => {
    return async dispatch => {
        try {

            dispatch({ type: GET_GROUPS_AND_BENEFITS_INIT });

            const url = `/products/views/benefitviews`;

            let params = {
                applicationName: 'CWS',
                viewIds: `${viewsIdsArray}`,
                locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
                effectiveDate: getDateStr(effDate, "mm/dd/yyyy")
            }

            let response = {};

            if (Env.ISPRODUCTION) {
                let apiResp = await axios.get(url, { params, timeout: 2000 });
                response = apiResp.data;
            } else {
                let sortedMock = [];
                console.log(mockBenefitViewData.planBenefitViews);
                viewIds.forEach(viewId => {
                    let test = mockBenefitViewData.planBenefitViews;
                    sortedMock.push(test.find((planBenefitView) => planBenefitView.viewId === viewId));
                })
                response["planBenefitViews"] = sortedMock;
            }

            dispatch(getGroupsAndBenefitsForAllViewIdsSuccess(response));

        } catch (err) {
            console.log("Error in getGroupsAndBenefitsOfAllViewsIds:", err);
            dispatch(getGroupsAndBenefitsForAllViewIdsFailure(err));
        }
    }
}

const getBenefitValuesForAllBenefitIdsFailure = (error) => {
    return {
        type: GET_BENEFIT_VALUES_FAILURE,
        error
    }
}

const getBenefitValuesForAllBenefitIdsSuccess = (data) => {
    return {
        type: GET_BENEFIT_VALUES_SUCCESS,
        data
    }
}

export const getBenefitValuesForAllBenefitIds = (productCode, benefitIdsArray, planIdsArray, effDate) => {
    return async dispatch => {
        try {

            dispatch({ type: GET_BENEFIT_VALUES_INIT });

            const url = `/products/` + productCode + `/plans/benefits/fetch`;
          
            //must pass effective date in ISO String Date format 
            let request = {
                benefitIds: benefitIdsArray,
                effectiveDate: effDate.toISOString(),
                locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
                planIds: planIdsArray
            };

            if (productCode !== "2000" && productCode !== "5000")
                request.networkId = "1"

            let response = {};

            if (Env.ISPRODUCTION) {
                let apiResp = await axios.post(url, request);
                response = apiResp.data;
            } else {
                let sortedMockDate = [];
                benefitIdsArray.forEach(benefitId => {
                    let mockBenefits = [];
                    if (planIdsArray.length == 1) {
                        mockBenefits = mockBenefitData.planBenefits.filter(planBenefit => planBenefit.benefitId === benefitId && planBenefit.planId === plans[0].planId);
                    } else {
                        mockBenefits = mockBenefitData.planBenefits.filter(planBenefit => planBenefit.benefitId === benefitId);
                    }
                    mockBenefits.forEach(mockBenefit => {
                        sortedMockDate.push(mockBenefit);
                    })
                })
                response = sortedMockDate;
            }

            dispatch(getBenefitValuesForAllBenefitIdsSuccess(response));

        } catch (err) {
            console.log("Error in getGroupsAndBenefitsOfAllViewsIds:", err);
            dispatch(getBenefitValuesForAllBenefitIdsFailure(err));
        }
    }
}

export const getDentalBenefitValuesForAllBenefitIds = async (productCode, benefitIdsArray, planIdsArray, effDate, networkCoverageId = 1) => {
    const url = `/products/` + productCode + `/plans/benefits/fetch`;
    console.log('effective date for dental',effDate)
    //must pass effective date in ISO String Date format 
    let request = {
        benefitIds: benefitIdsArray,
        effectiveDate: effDate.toISOString(),
        locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
        planIds: planIdsArray
    };
    // request.networkId = networkCoverageId

    let response = {};

    if (Env.ISPRODUCTION) {
        let apiResp = await axios.post(url, request);
        response = apiResp.data;
    } else {
        let sortedMockDate = [];
        benefitIdsArray.forEach(benefitId => {
            let mockBenefits = [];
            if (planIdsArray.length == 1) {
                mockBenefits = mockBenefitData.planBenefits.filter(planBenefit => planBenefit.benefitId === benefitId && planBenefit.planId === plans[0].planId);
            } else {
                mockBenefits = mockBenefitData.planBenefits.filter(planBenefit => planBenefit.benefitId === benefitId);
            }
            mockBenefits.forEach(mockBenefit => {
                sortedMockDate.push(mockBenefit);
            })
        })
        response = sortedMockDate;
    }
    return response;
}

export const getBenefits = async (productCode, benefitViewsObj, planInfoObjs, effDate) => {

    const url = `/products/` + productCode + `/plans/benefits/fetch`;
    let plansBenfitsInfo = planInfoObjs;
    const plans = [];

    plansBenfitsInfo.forEach(plan => { plans.push(plan.planId) });
    let request = buildBenefitsRequest(benefitViewsObj.benefitIds, plans, productCode, effDate)

    let response = {}
    if (Env.ISPRODUCTION) {
        let apiResp = await axios.post(url, request);
        response = apiResp.data.planBenefits;
    } else {
        let sortedMockDate = [];
        benefitViewsObj.benefitIds.forEach(benefitId => {
            let mockBenefits = [];
            if (plans.length == 1) {
                mockBenefits = mockBenefitData.planBenefits.filter(planBenefit => planBenefit.benefitId === benefitId && planBenefit.planId === plans[0].planId);
            } else {
                mockBenefits = mockBenefitData.planBenefits.filter(planBenefit => planBenefit.benefitId === benefitId);
            }
            mockBenefits.forEach(mockBenefit => {
                sortedMockDate.push(mockBenefit);
            })
        })
        response = sortedMockDate;
    }

    plans.forEach((plan, index) => {
        let planBenefits = [];
        /* Filter benefits for the plan */
        let planUnsortedBenfits = response.filter(benefit => benefit.planId === plan);

        /* Arrange the benefits by view order */
        benefitViewsObj.benefitIds.forEach(benefitsId => {
            for (let i = 0; i < planUnsortedBenfits.length; i++) {
                if (parseInt(planUnsortedBenfits[i].benefitId) === parseInt(benefitsId)) {
                    planBenefits.push(planUnsortedBenfits[i]);
                    break;
                }
            }
        });

        /* Add all the sorted benefits  to respective object in  plansBenfitsInfo array  */
        var plansBenfitsInfoIndex = plansBenfitsInfo.findIndex(obj => obj.planId === plan);
        if (plansBenfitsInfo[plansBenfitsInfoIndex].benefits === undefined || plansBenfitsInfo[plansBenfitsInfoIndex].benefits.length === 0) {
            plansBenfitsInfo[plansBenfitsInfoIndex].benefits = planBenefits;
        } else {
            while (plansBenfitsInfo[plansBenfitsInfoIndex].benefits.length > 0) {
                plansBenfitsInfo[plansBenfitsInfoIndex].benefits.pop();
            }
            planBenefits.forEach(planBenefit => {
                plansBenfitsInfo[plansBenfitsInfoIndex].benefits.push(planBenefit);
            })
        }

    })
    return plansBenfitsInfo;
}


export const getViews = async (productCodes, effDate) => {
    return async dispatch => {
        let viewArr = [];
        try {
            for (const productCode of productCodes) {
                let response = []
                if (Env.ISPRODUCTION) {
                    let params = { 
                        clientId: 'CWS',
                        productCode,
                        effectiveDate: getDateStr(effDate, "mm/dd/yyyy") 
                    }
                    let apiResp = await axios.get('/products/views',{ params });
                    response = apiResp.data.views;
                } else {
                    let data = mockViewData;
                    response = data.views.filter(view => view.productCode == productCode);
                }
                response.forEach(obj => {
                    viewArr.push(obj);
                });
            };
            dispatch(saveViewData(viewArr));
        } catch (error) {
            console.log(error)
        }
    }
}

export const getEasyPricingInfo = async (productCode, planYear, planId) => {
    let isEasyPricing = false;
    const url = `/products/` + productCode + `/plans/easypricing/planyear/` + planYear;
    let requestData = [
        planId
    ];
    
    try {
        let apiResp = await axios.post(url, requestData);
        let data = apiResp.data;
        if (data && data?.easyPricingPlans) {
            isEasyPricing = data?.easyPricingPlans[0]?.easyPricing;
        }
    } catch (error) {
        console.log("Error in getting Easy Pricing Info", error);
    }

    return isEasyPricing;
}

export const getPlanInfo = async (planId, productCode, countyCode, effDate, isOnExchange) => {
    let planInfo = {};
    let planIdSplit = planId.split("-");
    let csrLevelValue = planIdSplit[1];
    let csrLevel = null;
    if (isOnExchange) {
        csrLevel = (Constants.VALID_ON_EX_CSR_LEVELS)?.includes(csrLevelValue) ? csrLevelValue : '01';
    }
    let apiResp = await axios.get('/products/' + productCode + '/plans', {
        params: {
            countyCode,
            isOnExchange,
            locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
            effectiveDate: getDateStr(effDate, "mm/dd/yyyy"),
            csrLevel
        }
    });

    let plans = apiResp.data?.plans;
    planInfo = plans?.find((plan) => plan.planId == planId) || {};    
    console.log("Single Plan Info:", planInfo);  
    
    return planInfo;
}
