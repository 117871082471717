import * as Env from 'constants/Environment';
import * as ActionConstants from "actions/PeopleAction";
import { isEmpty, getLocale } from 'utils/commonUtils';

const initialState = {
    zipcode: "",
    fipscode: "",
    county: "",
    ratingAreaCode: "",
    quoteHouseholdSize: 0,
    helpmeChoosePlan: false,
    applicants: [],
    selectedPlan: [],
    aptcAmount: "",
    subsidyApplicantsInfo: {},
    subsidyDemographics: {},
    isPrebill: false,
    isOnExchange: false,
    houseHoldIncome: "",
    beginApplicationData: {},
    ciUnits: {},
    fromMenu: false,
    planFilters: {},
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    consentFB: null,
    consentHOI: null,
    consentFCL: null,
    consentUSAble: null,
    consentText: null,
    progressPath: '',
    progressTimeStamp: '',
    progressToken: Env.USER_TOKEN,
    progressSave: false,
    progressProduct: "",
    progressResume: false,
    progressCallBack: false,
    progressLanguage: getLocale(),
    isClosedProgressCallBack: false,
    isClosedSaveProgress: false,
    acceptCookieDisclaimer: false
}

const PeopleReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionConstants.SAVE_RAW_DEMOGRAPHICS_DATA:
            return {
                ...state,
                zipcode: action.payload.zipcode,
                fipscode: action.payload.fipscode,
                county: action.payload.county,
                ratingAreaCode: action.payload.ratingAreaCode,
                quoteHouseholdSize: action.payload.quoteHouseholdSize,
                applicants: action.payload.applicants
            }
        case ActionConstants.SAVE_ZIPCODE:
            return {
                ...state,
                zipcode: action.payload
            }
        case ActionConstants.SAVE_FIPSCODE:
            return {
                ...state,
                fipscode: action.payload
            }
        case ActionConstants.SAVE_COUNTY:
            return {
                ...state,
                county: action.payload
            }
        case ActionConstants.SAVE_RATING_AREA_CODE:
            return {
                ...state,
                ratingAreaCode: action.payload
            }
        case ActionConstants.SAVE_HELPME_CHOOSE_PLAN:
            return {
                ...state,
                helpmeChoosePlan: action.payload
            }
        case ActionConstants.SAVE_HOUSEHOLD_SIZE:
            return {
                ...state,
                quoteHouseholdSize: action.payload
            }
        case ActionConstants.SAVE_FROM_MENU:
            return {
                ...state,
                fromMenu: action.payload
            }
        case ActionConstants.RESET_FROM_MENU:
            return {
                ...state,
                fromMenu: action.payload
            }
        case ActionConstants.SAVE_APTC_AMOUNT:
            return {
                ...state,
                aptcAmount: action.payload
            }
        case ActionConstants.SAVE_APPLICANTS:
            return {
                ...state,
                applicants: action.payload
            }
        case ActionConstants.SAVE_SELECTED_PLAN:
            state.selectedPlan.push(action.payload);
            return {
                ...state,
                selectedPlan: [...state.selectedPlan]
            }
        case ActionConstants.REMOVE_SELECTED_PLAN:
            return {
                ...state,
                selectedPlan: state.selectedPlan.filter((item, index) => index !== action.payload)
            }
        case ActionConstants.RESET_CART_DATA:
            return {
                ...state,
                selectedPlan: []
            };
        case ActionConstants.SAVE_BEGIN_APPLICATION_DATA:
            return {
                ...state,
                beginApplicationData: action.payload
            }
        case ActionConstants.RESET_BEGIN_APPLICATION_DATA:
            return {
                ...state,
                beginApplicationData: {}
            };
        case ActionConstants.SAVE_HOUSEHOLD_INCOME:
            return {
                ...state,
                houseHoldIncome: action.payload
            }
        case ActionConstants.SAVE_CAMPAIGN_RCV:
            const payload = action.payload || {};
            return {
                ...state,
                zipcode: payload.zip,
                fipscode: payload.fips_code,
                county: payload.county_name,
                keycode: payload.keycode,
                helpmeChoosePlan: (payload.flow === 'wizard') ? true : false
            }
        case ActionConstants.RESET_PEOPLE_REDUCER:
            return {
                ...state,
                zipcode: initialState.zipcode,
                fipscode: initialState.fipscode,
                county: initialState.county,
                ratingAreaCode: initialState.ratingAreaCode,
                helpmeChoosePlan: initialState.helpmeChoosePlan,
                quoteHouseholdSize: initialState.quoteHouseholdSize,
                applicants: initialState.applicants,
                selectedPlan: initialState.selectedPlan,
                isPrebill: initialState.isPrebill,
                isOnExchange: initialState.isOnExchange
            }

        case ActionConstants.SAVE_SUBSIDY_APPLICANTS_INFO:
            return {
                ...state,
                subsidyApplicantsInfo: action.data
            }

        case ActionConstants.SAVE_HOUSEHOLD_INCOME:
            return {
                ...state,
                subsidyDemographics: action.data
            }

        case ActionConstants.RESET_SUBSIDY_DATA:
            return {
                ...state,
                subsidyApplicantsInfo: initialState.subsidyApplicantsInfo,
                subsidyDemographics: initialState.subsidyDemographics
            };

        case ActionConstants.SAVE_PREBILL:
            return {
                ...state,
                isPrebill: true,
                zipcode: action.data.zipcode,
                fipscode: action.data.fipscode,
                county: action.data.county,
                quoteHouseholdSize: action.data.quoteHouseholdSize,
                applicants: action.data.applicants
            }

        case ActionConstants.SAVE_ONEXCHANGE:
            return {
                ...state,
                isOnExchange: action.data
            }

        case ActionConstants.SAVE_CI_UNITS:
            return {
                ...state,
                ciUnits: action.data
            }

        case ActionConstants.SAVE_FILTERS:
            return {
                ...state,
                planFilters: action.data
            }

        case ActionConstants.RESET_SAVE_FILTERS:
            return {
                ...state,
                planFilters: initialState.planFilters
            }

        case ActionConstants.ADD_PROGRESS_INFO:
            const genInfoPayload = action.payload;
            return {
                ...state,
                ...(genInfoPayload.hasOwnProperty('firstName') && genInfoPayload.firstName != '' && {firstName: genInfoPayload.firstName}),
                ...(genInfoPayload.hasOwnProperty('lastName') && genInfoPayload.lastName != '' && {lastName: genInfoPayload.lastName}),
                ...(genInfoPayload.hasOwnProperty('email') && genInfoPayload.email != '' && {email: genInfoPayload.email}),
                ...(genInfoPayload.hasOwnProperty('phone') && genInfoPayload.phone != '' && {phone: genInfoPayload.phone}),
                ...(genInfoPayload.hasOwnProperty('consentFB') && !isEmpty(genInfoPayload?.consentFB) && {consentFB: genInfoPayload?.consentFB[0]}),
                ...(genInfoPayload.hasOwnProperty('consentHOI') && !isEmpty(genInfoPayload?.consentHOI) && {consentHOI: genInfoPayload?.consentHOI[0]}),
                ...(genInfoPayload.hasOwnProperty('consentFCL') && !isEmpty(genInfoPayload?.consentFCL) && {consentFCL: genInfoPayload?.consentFCL[0]}),
                ...(genInfoPayload.hasOwnProperty('consentUSAble') && !isEmpty(genInfoPayload?.consentUSAble) && {consentUSAble: genInfoPayload?.consentUSAble[0]}),
                ...(genInfoPayload.hasOwnProperty('consentText') && !isEmpty(genInfoPayload?.consentText) && {consentText: genInfoPayload?.consentText[0]})
            }

        case ActionConstants.UPDATE_PROGRESS_INFO:
            const progressInfoPayload = action.payload;
            return {
                ...state,
                progressPath: progressInfoPayload?.progressPath || '',
                progressTimeStamp: new Date().toISOString(),
                progressSave: progressInfoPayload?.progressSave || false,
                progressProduct: progressInfoPayload?.progressProduct || '',
                ...(progressInfoPayload.hasOwnProperty('progressResume') && {progressResume: progressInfoPayload?.progressResume || false}),
                progressCallBack: progressInfoPayload?.progressCallBack || false
            }
        
        case ActionConstants.SAVE_PEOPLE_REDUCER_DATA:
            return {
                ...state,
                ...action.payload
            };

        case ActionConstants.CLOSE_PROGRESS_CALLBACK:
            return {
                ...state,
                isClosedProgressCallBack: action.payload
            }
        
        case ActionConstants.CLOSE_SAVE_PROGRESS:
            return {
                ...state,
                isClosedSaveProgress: action.payload
            }
        
        case ActionConstants.ACCEPT_COOKIE_DISCLAIMER:
            return {
                ...state,
                acceptCookieDisclaimer: action.payload
            }

        default:
            return state;
    }
}

export default PeopleReducer