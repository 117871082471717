import React from 'react';
import moment from "moment";
import { changeLanguage } from 'i18next';
import { NumericFormat } from 'react-number-format';

import * as Constants from "constants/Constants";

/**
 * Accept an item and return true if empty
 *
 * @param {string | object} item
 * @returns {boolean} Cookie Value or null
 */
export const isEmpty = (item) => {
  if (item === null || item === undefined) return true;
  if (Array.isArray(item) || typeof item === "string") {
    return item.length === 0;
  }
  if (typeof item === "object") {
    return Object.getOwnPropertyNames(item).length === 0;
  }

  return false;
};

/**
 * Accept a string date and optional format and return formatted string
 *
 * @param {string} strDate
 * @param {string} format
 * @returns {string} formatted date string
 */
export const formatDate = (strDate, format = "MM/DD/YYYY") => {
  try {
    var d = new Date(strDate);
    return moment(d).format(format);
  } catch (err) {
    return strDate;
  }
};

/**
 * Accept a number value as a string and optional prefix and return formatted string
 *
 * @param {string} value
 * @param {string} prefix
 * @returns {string} formatted price string
 */
export const priceFormat = (value, prefix = "") => {
  const v = parseFloat(value);
  if (Number.isNaN(v)) return value;
  const c = "$";
  return `${prefix}${c}${v.toFixed(2)}`;
};

/**
 * Accept a lang value as a string and return true if it is different from the current language in the app
 *
 * @param {string} strLang
 * @returns {boolean}
 */
export const isLanguageChanged = (strLang) => {
  const nLang = (strLang || "").toLowerCase();
  const lsLang = (localStorage['i18nextLng'] || "").toLowerCase();
  return (nLang.length > 0 && nLang !== lsLang);
};

/**
 * Switch the current language in the app es/en
 *
 */
export const onLanguageChange = () => {
  const switchLangTo = (localStorage['i18nextLng'] === "en") ? "es" : "en";
  changeLanguage(switchLangTo);
};

/**
 * Returns current Accessibility color of cws-ui
 */
export const getAccessibilityColor = () => {

  let appTheme = localStorage['theme'];

  if (appTheme == 'lowcontrast')
    return "Blue"
  else if (appTheme == 'highcontrast')
    return "Black"
  else
    return "White"
}

/**
 * Returns current Locale value cws-ui
 */
export const getLocale = () => {
  if(localStorage['i18nextLng'] === "en") {
    return Constants.ENG;
  } else if(localStorage['i18nextLng'] === "es") {
    return Constants.ESP;
  } else {
    return Constants.ENG;
  }
}

/**
 * Returns date in provided format
 */
export const getDateStr = (date, format = "mm/dd/yyyy") => {

  if (date == null || date == undefined)
    return null;

  try {
    const dateObj = new Date(date);

    if (format === "mmddyyyy")
      return ('0' + (dateObj.getMonth() + 1)).slice(-2) + ('0' + dateObj.getDate()).slice(-2) + dateObj.getFullYear();
    else if (format === "mm/dd/yyyy")
      return dateObj.toLocaleDateString('en-US');
    else if (format === "yyyy-mm-dd")
      return dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObj.getDate()).slice(-2);
  } catch (e) {
    console.log("Error while formating the date", e);
  }
}

/**
 * Accept a value as a string and return formatted string
 *
 * @param {string} value
 * @returns {string} formatted phone number string
 */
 export const formatPhoneNumber = (value) => {
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) {
    return phoneNumber;
  }

  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

export const formatNumber = (value) => {
  const nFormat = new Intl.NumberFormat();
  return nFormat.format(value.replaceAll(',', ''))
}
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

export const formatCurrency =  (amount) => {
  if (amount === undefined || amount === "") {
    amount =0;
  } 
  return (<NumericFormat value={Number(amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />);
}

export const consumerSegment = (productSegmentAgeCalucate) =>{
  let productSegmentAge = '';
  if (productSegmentAgeCalucate > 65) { 
    return productSegmentAge = 'Over65' 
  } 
  else { 
    return productSegmentAge = 'Under65' 
  };
}

export const planEnrollYear = (segment,isOnExchange,onExEffectiveDate,offExEffectiveDate,dentalEffectiveDate,usableEffectiveDate) =>{
  let enrol_year='';
  if(segment === Constants.PRODUCT_SEGMENT_HEALTH) {
    return enrol_year = isOnExchange ? onExEffectiveDate : offExEffectiveDate
  }
  else if(segment === Constants.PRODUCT_SEGMENT_DENTAL) {
    return enrol_year = dentalEffectiveDate
  }
  else if(segment === Constants.PRODUCT_SEGMENT_USABLE) {
    return enrol_year = usableEffectiveDate
  }
}

export const planCategoryName =(planName) =>{
let category='';
if(planName.includes("BlueSelect")) return category ="BlueSelect - EPO/PPO"
else if(planName.includes("BlueOptions")) return category ="BlueOptions - PPO"
else if(planName.includes("BlueCare")) return category="BlueCare - HMO"
else if(planName.includes("myBlue")) return category="myBlue - HMO"
else if(planName.includes("BlueDental")) return category ="BlueDental"
else if(planName.includes("Accident")) return category ="Accident"
else if(planName.includes("Hospital")) return category="Hospital"
else if(planName.includes("Critical")) return category="Critical"
}

export const productSegmentName =(planCategory)=>{
  let productSegment = '';
  if (planCategory == 'Hospital Indemnity' || planCategory == 'Accident Policy' || planCategory == 'Critical Illness') {
      return productSegment = Constants.PRODUCT_SEGMENT_USABLE;
  } else if (planCategory == 'QDental' || planCategory == 'NQDental') {
    return  productSegment = Constants.PRODUCT_SEGMENT_DENTAL;
  } else if (planCategory == 'health') {
    return   productSegment = Constants.PRODUCT_SEGMENT_HEALTH;
  }
}

export const planFlow = (isOnExchange,isMultiEnrollmentGroupApplication,isSubsidyFlow,isAcc,isAqt,isMOT) =>{
  let flow ="";
  if(isOnExchange && isMultiEnrollmentGroupApplication) {
    return flow = "Direct Enrollment"}
  else if(isOnExchange && isSubsidyFlow){
    return flow = "Subsidy"}
  else if(isAcc){
    return flow = 'ACC'}
  else if(isAqt){
    return flow = 'AQT'}
  else if(isMOT){
    return flow = 'MOT'}
  else return flow = "CWS"
}

/**
 * Parse JSON string otherwise return false.
 *
 * @param {string} jsonString
 * @returns {object | boolean} JSON object or false 
 */
export const parseJson = (jsonString) => {
  try {
    var o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
        return o;
    }
  }
  catch (e) {
    console.log("JSON is not valid", e);
    return false;
  }

  return false;  
};

/**
 * Accept email as a string and check whether it is valid
 *
 * @param {string} email
 * @returns {boolean} return true|false
 */
export const isValidEmail = (email) => {

  if (email == null || email == undefined) {
    return false;
  }

  const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
  const isValid = emailRegex.test(email);

  return isValid;
}

/**
 * Accept name as a string and check whether it is valid
 *
 * @param {string} name
 * @returns {boolean} return true|false
 */
export const isValidName = (name) => {

  if (name == null || name == undefined) {
    return false;
  }

  // valid cases:
  // Mathias d'Arras
  // Martin Luther King, Jr.
  // Hector Sausage-Hausen

  // invalid cases:
  // ..Mathias
  // Martin king, Jr.-

  const nameRegex = new RegExp(/^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i);
  const isValid = nameRegex.test(name);

  return isValid;
}

/**
 * Accept phone as a string and check whether it is valid
 *
 * @param {string} input
 * @returns {boolean} return true|false
 */
export const validatePhoneNumber = (input) => {

  if (input == null || input == undefined) {
    return false;
  }

  // valid cases:
  // (904) 555-5555
  // +919367788755
  // 8989829304
  // +16308520397
  // 786-307-3615

  // invalid cases:
  // 789
  // 123765
  // 1-1-1
  // +982

  const inputRegex = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
  const isValid = inputRegex.test(input);

  return isValid;
}