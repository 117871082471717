import { formatDate, isEmpty } from "../utils/commonUtils";
import { DEPENDENT_CHILD, APPLICANT, SPOUSE, DOMESTIC_PARTNER } from '../constants/ApplicantDemographicConstants';
import { calculateAge } from "utils/shoppingUtils";

export const GROUP_SELECTION_PAGE = 0;
export const PRIMARY_SELECTION_PAGE = 1;
export const ADJUST_TAX_CREDIT_PAGE = 2;
export const DEPENDENT_RELATIONSHIPS = ["dependent_child"];

export const getTobaccoChoices = (t) => {
    return ([
        { label: t('tobaccoChoices.select'), value: "" },
        { label: t('tobaccoChoices.N'), value: "N" },
        { label: t('tobaccoChoices.Y-6'), value: "Y-6" },
        { label: t('tobaccoChoices.Y-7-12'), value: "Y-7-12" },
        { label: t('tobaccoChoices.Y-13-36'), value: "Y-13-36" },
        { label: t('tobaccoChoices.Y-37'), value: "Y-37+" }
    ]);
};

export const calucateAge = (date) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
    }
    return age;
}

export const sameGroupSelection = (groupsSelection, applicantsGroups) => {
    const gsKeys = (groupsSelection || []).map((gs) => `${gs.id}-${gs.groupNum}`);
    const agKeys = [];
    (applicantsGroups || []).forEach((g) => {
        (g || []).forEach((a) => {
            agKeys.push(`${a.id}-${a.groupNum}`);
        })
    })
    return gsKeys.sort().join('|') === agKeys.sort().join('|');
};

export const buildApplicantsGroups = (groupsSelection, applicants, maxTobaccoAge) => {
    const objGroupsIds = {};
    (groupsSelection || []).forEach((gs) => {
        if (objGroupsIds[gs.groupNum]) {
            objGroupsIds[gs.groupNum].push(gs.id);
        } else {
            objGroupsIds[gs.groupNum] = [gs.id];
        }
    })
    const groupNums = Object.keys(objGroupsIds);
    let enrichApplicantsGroups = [];
    (groupNums || []).forEach((groupNum) => {
        const group = [];
        const applicantsIds = objGroupsIds[groupNum];
        const primary = applicantsIds.length == 1 ? "Y" : "N";
        (applicantsIds || []).forEach((id) => {
            const applicant = (applicants || []).find((a) => a.id == id);
            if (calucateAge(applicant.dob) >= maxTobaccoAge) {
                group.push({ ...applicant, groupNum, showTobaccoQuestion: true, primary, error_tobaccoUse: "" });
            } else {
                group.push({ ...applicant, groupNum, showTobaccoQuestion: false, primary });
            }
        });
        enrichApplicantsGroups.push(group);
    });
    return enrichApplicantsGroups;
};

export const buildApplicantList = (applicants) => {
    return (applicants || []).map((a) => {
        const addrs = a.addresses;
        const addr = addrs?.length > 0 ? addrs.find(ad => ad.type == "HOME_ADDRESS") : [];
        let formatedDOB = formatDate(a.dob);
        return {
            id: a.id,
            dob: formatedDOB || "",
            age: calculateAge(formatedDOB),
            firstName: a.firstName || "",
            lastName: a.lastName || "",
            gender: a.gender || "",
            zipCode: addr?.zip || "",
            county: addr?.county || "",
            relationship: a.relationList?.additionalProp1 || "",
            allowedMetals: a.eligibility?.allowedPlanMetalLevels || [],
            isQHPEligible: a.eligibility?.qhpEligible || false,
            isCSREligible: a.eligibility?.csrEligible || false,
            csrLevel: a.eligibility?.csrLevel,
            isAPTCEligible: a.eligibility?.aptcEligible || false,
            isCurrentPlanOnly: a.eligibility?.crntPlnOnlyInd || false,
            isNewMember: a.eligibility?.newMbrInd || false,
            monthlyAPTCAmount: a.eligibility?.monthlyAPTCAmount || 0
        };
    });
};

export const isDE = (applicants) => {
    let domesticPartnerIndex = (applicants || []).findIndex((applicant) => applicant.relationship === DOMESTIC_PARTNER);

    if (domesticPartnerIndex > -1) {
        return true;
    }        
    return false;
};

export const calculatedOnExEffectiveDate = (coverageYear, onExEffectiveDate, insurancePolicyDtlsInd = false, sysDate, oepSepTest) => {
    let today = new Date();
    if (oepSepTest) {
        today = new Date(sysDate);
    }
    let currentYear = today.getFullYear();
    let nextYear = currentYear + 1;
    let checkFromDate = `12/01/${currentYear}`; // MM/DD/YYYY Format
    let checkToDate = `12/15/${currentYear}`; // MM/DD/YYYY Format
    let earliestEffectiveDate = `01/01/${nextYear}`; // MM/DD/YYYY Format  
    let isBetweenDateRange = (today >= new Date(checkFromDate) && today <= new Date(checkToDate));
    
    // Update System Effective Date to Current Date
    // Rules:
    // 1. Has existing coverage
    // 2. Received coverage year is same as current year
    // 3. System effective date is 1st Jan of Next Year
    // 4. Current date between 12/1 to 12/15
    if (insurancePolicyDtlsInd == true && coverageYear == currentYear && formatDate(onExEffectiveDate) == earliestEffectiveDate && isBetweenDateRange) {
        return formatDate(today);
    }

    return formatDate(onExEffectiveDate);
}

export const isApplicationEligible = (coverageYear, onExEffectiveDate, insurancePolicyDtlsInd = false, sysDate, oepSepTest) => {
    let today = new Date();
    if (oepSepTest) {
        today = new Date(sysDate);
    }
    let currentYear = today.getFullYear();
    let nextYear = currentYear + 1;
    let checkFromDate = `12/01/${currentYear}`; // MM/DD/YYYY Format
    let checkToDate = `12/15/${currentYear}`; // MM/DD/YYYY Format
    let earliestEffectiveDate = `01/01/${nextYear}`; // MM/DD/YYYY Format  
    let isBetweenDateRange = (today >= new Date(checkFromDate) && today <= new Date(checkToDate));
    
    // Application Not eligible if -
    // 1. Has no existing coverage
    // 2. Received coverage year is same as current year
    // 3. System effective date is 1st Jan of Next Year
    // 4. Current date between 12/1 to 12/15
    if (insurancePolicyDtlsInd == false && coverageYear == currentYear && formatDate(onExEffectiveDate) == earliestEffectiveDate && isBetweenDateRange) {
        return false;
    }

    return true;
}

export const getMegEffectiveDate = (applicants, nonSEPCicEligible) => {
    let effectiveDate = "";
    let firstEligibleApplicant = (applicants || []).find((applicant) => applicant.eligibility?.qhpEligible) || {};

    if (!isEmpty(firstEligibleApplicant)) {
        let enrollmentPeriodData = firstEligibleApplicant?.enrollmentPeriod ?? {};
        if (!isEmpty(enrollmentPeriodData)) {
            // SEP Effective Date
            if (enrollmentPeriodData?.sepIndicator === true && enrollmentPeriodData?.iepIndicator === false) {
                effectiveDate = enrollmentPeriodData?.sepEarliestEffectiveDate ?? "";
            }
            // AEP Effective Date
            if (enrollmentPeriodData?.iepIndicator === true) {
                effectiveDate = enrollmentPeriodData?.iepEarliestEffectiveDate ?? "";
            }
            // NON SEP CIC - AEP Effective Date
            if (nonSEPCicEligible && enrollmentPeriodData?.sepIndicator === false && enrollmentPeriodData?.iepIndicator === false) {
                effectiveDate = enrollmentPeriodData?.iepEarliestEffectiveDate ?? "";
            }
        }
    } 

    return effectiveDate;
};

export const getMedicareTransitionsMessage = (t, applicantList, nonSEPCicEligible, sysDate, oepSepTest, sepType) => {
    let today = new Date();
    if (oepSepTest) {
        today = new Date(sysDate);
    }
    const sepInitial = sepType == "INITIAL_APP" ? true : false;
    const firstOfNextMonth = today.getMonth() == 11 ? new Date(today.getFullYear() + 1, 0 , 1) : new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const lastDayOfNextMonth = new Date(firstOfNextMonth.getFullYear(), firstOfNextMonth.getMonth()+1, 0);
    const eligibleMembers = applicantList.filter(a => a?.eligibility?.qhpEligible) || [];

    let medMembers = [];
    eligibleMembers.forEach(function(a) {
        let medMemberObj = {};        
        if (!isEmpty(a?.medicareStartDate) && (nonSEPCicEligible == true || a?.enrollmentPeriod?.sepIndicator == true)) {
            const medStartDate = new Date(a?.medicareStartDate);
            const sepIndicator = a?.enrollmentPeriod?.sepIndicator || false;
            const sepEffectiveDate = new Date(a?.enrollmentPeriod?.sepEarliestEffectiveDate);
            const iepEffectiveDate = new Date(a?.enrollmentPeriod?.iepEarliestEffectiveDate);
            const sepStart = new Date(a?.enrollmentPeriod?.sepStartDate);
            const sepEnd = new Date(a?.enrollmentPeriod?.sepEndDate);

            const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            const medicareDate = new Date(medStartDate.getFullYear(), medStartDate.getMonth(), medStartDate.getDate());
            const sepEffDate = new Date(sepEffectiveDate.getFullYear(), sepEffectiveDate.getMonth(), sepEffectiveDate.getDate());
            const iepEffDate = new Date(iepEffectiveDate.getFullYear(), iepEffectiveDate.getMonth(), iepEffectiveDate.getDate());
            const sepStartDate = new Date(sepStart.getFullYear(), sepStart.getMonth(), sepStart.getDate());
            const sepEndDate = new Date(sepEnd.getFullYear(), sepEnd.getMonth(), sepEnd.getDate());
            const effectiveDate = nonSEPCicEligible ? iepEffDate : sepEffDate;
            const effectiveDatePrevDay = new Date(effectiveDate.getFullYear(), effectiveDate.getMonth(), effectiveDate.getDate()-1);

            medMemberObj.medicareStartDate = formatDate(medicareDate);
            medMemberObj.medDateIsToday = formatDate(medicareDate) == formatDate(currentDate) ? true : false;
            medMemberObj.medDateAfterToday = medicareDate > currentDate ? true : false;
            medMemberObj.medDateOnAndAfterToday = medicareDate >= currentDate ? true : false;
            medMemberObj.medDateBeforeToday = medicareDate < currentDate ? true : false;
            medMemberObj.medDateFirstOfNext = formatDate(medicareDate) == formatDate(firstOfNextMonth) ? true : false;
            medMemberObj.medDateAfterFirstOfNext = medicareDate > firstOfNextMonth ? true : false;
            medMemberObj.sepIndicator = sepIndicator;
            medMemberObj.sepStartDate = formatDate(sepStartDate);
            medMemberObj.sepEndDate = formatDate(sepEndDate);
            medMemberObj.effectiveDate = formatDate(effectiveDate);
            medMemberObj.effectiveDatePrevDay = formatDate(effectiveDatePrevDay);
            medMemberObj.effectiveDateAfterToday = effectiveDate > currentDate ? true : false;
            medMemberObj.effectiveDateBeforeToday = effectiveDate < currentDate ? true : false;
            medMemberObj.effectiveDateOnAndBeforeToday = effectiveDate <= currentDate ? true : false;
            medMemberObj.medDateAfterNextMonth = medicareDate > lastDayOfNextMonth ? true : false;

            medMembers.push(medMemberObj);
        }
    });

    console.log("Medicare Transitions Members Data: ", medMembers);

    let totalMedTransMember = medMembers.length;
    let medicareTransitionsMsg = null;
    // Single Medicare Transition Member
    if (totalMedTransMember === 1) {
        const medMember = medMembers[0];
        const messageTokens = {
            sepEndDate: medMember.sepEndDate, 
            effectiveDate: medMember.effectiveDate, 
            medicareStartDate: medMember.medicareStartDate
        };
        // 1. NON-RETRO SEP CIC where current date is BEFORE Medicare Start Date on first of next month
        if (!nonSEPCicEligible && medMember.effectiveDateAfterToday && medMember.medDateOnAndAfterToday && medMember.medDateFirstOfNext) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message1', messageTokens);
        // 2. NON SEP CIC where current date is BEFORE Medicare Start Date on first of next month
        } else if (nonSEPCicEligible && medMember.medDateOnAndAfterToday && medMember.medDateFirstOfNext) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message2', messageTokens);
        // 3. NON-RETRO SEP CIC where current date is AFTER Medicare Start Date
        }  else if (!nonSEPCicEligible && medMember.effectiveDateAfterToday && medMember.medDateBeforeToday) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message3', messageTokens);
        // 4. NON SEP CIC where current date is AFTER Medicare Start Date
        } else if (nonSEPCicEligible && medMember.medDateBeforeToday) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message4', messageTokens);
        // 5. Retro SEP CIC with Medicare Start Date provided by consumer, either in past or first of next calendar month
        } else if (!nonSEPCicEligible && medMember.effectiveDateOnAndBeforeToday && (medMember.medDateBeforeToday || medMember.medDateFirstOfNext)) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message5', messageTokens);
        // 6. RETRO SEP CIC with Medicare Start Date provided by consumer, beyond first of next calendar month
        } else if (!nonSEPCicEligible && !sepInitial && medMember.effectiveDateOnAndBeforeToday && medMember.medDateAfterFirstOfNext) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message6', messageTokens);
        // 7. NON-RETRO SEP CIC with Medicare Start Date beyond first of next calendar month
        } else if (!nonSEPCicEligible && !sepInitial && medMember.effectiveDateAfterToday && medMember.medDateAfterFirstOfNext) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message7', messageTokens);
        // 8. NON SEP CIC with Medicare Start Date beyond first of next calendar month
        } else if (nonSEPCicEligible && !sepInitial && medMember.medDateAfterFirstOfNext) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message8', messageTokens);
        // 9. SEP Initial with Medicare Start Date beyond first next calendar month
        } else if (sepInitial && medMember.medDateAfterFirstOfNext) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message9', messageTokens);
        } else {
            medicareTransitionsMsg = null;
        }
    } else if (totalMedTransMember > 1) {        
        const pastMbrs = medMembers.filter((m) => m.medDateBeforeToday === true);
        const firstofNextMbrs = medMembers.filter((m) => m.medDateFirstOfNext === true);
        const afterFirstofNextMbrs = medMembers.filter((m) => m.medDateAfterFirstOfNext === true);
        const afterNextMonthMbrs = medMembers.filter((m) => m.medDateAfterNextMonth === true);
 
        const pastMbrSingle = pastMbrs.length === 1 ? true : false;
        const medMbrPastMultiple = pastMbrs.length > 1 ? true : false;
        const firstofNextMbrSingle = firstofNextMbrs.length === 1 ? true : false;
        const firstofNextMbrMultiple = firstofNextMbrs.length > 1 ? true : false;
        const afterFirstofNextMbrSingle = afterFirstofNextMbrs.length === 1 ? true : false;
        const afterFirstofNextMbrMultiple = afterFirstofNextMbrs.length > 1 ? true : false;
        const afterNextMonthMbrsSingle = afterNextMonthMbrs.length === 1 ? true : false;
        const afterNextMonthMbrMultiple = afterNextMonthMbrs.length > 1 ? true : false;

        const medMember = medMembers[0];
        const messageTokens = {
            sepEndDate: medMember.sepEndDate, 
            effectiveDate: medMember.effectiveDate, 
            medicareStartDate: medMember.medicareStartDate,
            effectiveDatePrevDay: medMember.effectiveDatePrevDay
        };
        // 10a. NON-RETRO SEP CIC with Medicare Start Date(s): past AND first of next month
        if (!nonSEPCicEligible && medMember.effectiveDateAfterToday && pastMbrSingle && firstofNextMbrSingle && !afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message10a', messageTokens);
        // 10b. NON SEP CIC with Medicare Start Date(s): past AND first of next month
        } else if (nonSEPCicEligible && pastMbrSingle && firstofNextMbrSingle && !afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message10b', messageTokens);
        // 11a. NON-RETRO SEP CIC with Medicare Start Date(s): past AND first of next month AND beyond first of next calendar
        } else if (!nonSEPCicEligible && !sepInitial && medMember.effectiveDateAfterToday && pastMbrSingle && firstofNextMbrSingle && afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message11a', messageTokens);
        // 11b. NON SEP CIC with Medicare Start Date(s): past AND first of next month AND beyond first of next calendar month
        } else if (nonSEPCicEligible && !sepInitial && pastMbrSingle && firstofNextMbrSingle && afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message11b', messageTokens);
        // 12a. NON-RETRO SEP CIC with Medicare Start Date(s): past AND beyond first of next calendar month
        } else if (!nonSEPCicEligible && !sepInitial && medMember.effectiveDateAfterToday && pastMbrSingle && afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message12a', messageTokens);
        // 12b. NON SEP CIC with Medicare Start Date(s): past AND beyond first of next calendar month
        } else if (nonSEPCicEligible && !sepInitial && pastMbrSingle && afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message12b', messageTokens);
        // 13a. NON-RETRO SEP CIC with Medicare Start Date(s): first of next month AND beyond first of next calendar month
        } else if (!nonSEPCicEligible && !sepInitial && medMember.effectiveDateAfterToday && firstofNextMbrSingle && afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message13a', messageTokens);
        // 13b. NON SEP CIC with Medicare Start Date(s): first of next month AND beyond first of next calendar month
        } else if (nonSEPCicEligible && !sepInitial && firstofNextMbrSingle && afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message13b', messageTokens);
        // 14a. NON-RETRO SEP CIC with Medicare Start Date(s): multiple beyond first of next calendar month
        } else if (!nonSEPCicEligible && !sepInitial && medMember.effectiveDateAfterToday && afterFirstofNextMbrMultiple) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message14a', messageTokens);
        // 14b. NON SEP CIC with Medicare Start Date(s): multiple beyond next calendar month
        } else if (nonSEPCicEligible && afterNextMonthMbrMultiple) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message14b', messageTokens);
        // 15. Initial SEP with Medicare Start Date(s): past AND/OR first of next month, AND beyond first of next calendar month
        } else if (sepInitial && ((pastMbrSingle && firstofNextMbrSingle) || (pastMbrSingle || firstofNextMbrSingle)) && afterFirstofNextMbrSingle) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message15', messageTokens);
        // 16. Initial SEP with Medicare Start Date(s): multiple beyond first of next calendar month
        } else if (sepInitial && afterFirstofNextMbrMultiple) {
            medicareTransitionsMsg = t('medicareTransitionsMsgs.message16', messageTokens);
        } else {
            medicareTransitionsMsg = null;
        }       
    } else {
        medicareTransitionsMsg = null;
    }

    if (medicareTransitionsMsg) {
        return medicareTransitionsMsg;
    }

    return null;
};
